import fabianoGregorio from "../../../../assets/images/userReviews/fabiano-gregorio.jpg";
import luigiSariaAvatar from "../../../../assets/images/userReviews/luigi-saria.jpg";
import jessicaMariaDosSantos from "../../../../assets/images/userReviews/jessica-maria-dos-santos.jpg";
import monicaBienemannAvatar from "../../../../assets/images/userReviews/monica-bienemann.jpg";
import paulaBarbosaAvatar from "../../../../assets/images/userReviews/paula-barbosa.jpg";
import muriloBatistaAvatar from "../../../../assets/images/userReviews/murilo-batista.jpg";

export const userReviewsData = [
  {
    avatar: jessicaMariaDosSantos,
    author: "Jéssica Maria dos Santos",
    authorOccupation: "",
    comment: `App muito eficaz."`
  },
  {
    avatar: fabianoGregorio,
    author: "Fabiano Gregório",
    authorOccupation: "Pastor",
    comment: `Aplicativo é muito bom, uma das coisas que trás é a praticidade de você colocar o cartão quando vence sem ter que voltar ao carro, como era antigamente no papel. Além de tudo ainda te avisa nos 10 minutos restantes para você não ser multado por esquecimento. Parabéns"`
  },
  {
    avatar: luigiSariaAvatar,
    author: "Luigi Saria",
    authorOccupation: "",
    comment: `Aplicativo muito bom e funcional. Gostei das opções dos alarmes de tempo e principalmente de poder ativar/desativar esses alarmes. Uso desde que implementaram a zona azul digital. Nunca tive problemas com ele. Recomendo!"`
  },
  {
    avatar: monicaBienemannAvatar,
    author: "Monica Bienemann",
    authorOccupation: "",
    comment: `Muito fácil de usar, simples e útil...e me avisou quando acabou o período, para eu recarregar. Gostei! :)"`
  },
  {
    avatar: paulaBarbosaAvatar,
    author: "Paula Barbosa",
    authorOccupation: "",
    comment: `Muito bom, não preciso voltar no carro para atualizar as horas e também não tem mais gastos de papel a toa, o planeta agradece."`
  },
  {
    avatar: muriloBatistaAvatar,
    author: "Murilo Batista",
    authorOccupation: "",
    comment: `O uso foi sem problemas. Respostas bem rápida as solicitações e com alerta próximo de expirar o tempo. Ótimo app."`
  }
];
