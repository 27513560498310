import styled from "styled-components";

export const Container = styled.div`
  height: 4px;
  width: 100%;
  background: linear-gradient(
    to right,
    ${props => props.theme.colors.lightBlue} 0%,
    ${props => props.theme.colors.zonaAzulFacilSpBlue} 100%
  );
`;
