import styled from "styled-components";

export const Button = styled.a`
  text-decoration: none;

  button {
    width: 100%;
    &.button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      border: double 2px transparent;
      height: 44px;
      border-radius: 50px;
      background-image: linear-gradient(white, white),
        radial-gradient(
          circle at top left,
          ${props => props.theme.colors.lightBlue} 0%,
          ${props => props.theme.colors.zonaAzulFacilSpBlue} 100%
        );
      background-origin: border-box;
      background-clip: content-box, border-box;

      div.button-text-container {
        display: grid;
        align-items: center;
        margin: 0 16px;
        grid-template-columns: 30px 1fr;
        grid-column-gap: 8px;
        grid-template-areas:
          "button-icon button-top-text"
          "button-icon button-bottom-text";

        span.button-icon {
          grid-area: button-icon;

          svg * {
            fill: url(#lgrad);
          }
        }

        span.button-top-text {
          grid-area: button-top-text;
          background: -webkit-linear-gradient(
            ${props => props.theme.colors.lightBlue},
            ${props => props.theme.colors.zonaAzulFacilSpBlue}
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        span.button-bottom-text {
          grid-area: button-bottom-text;
          background: -webkit-linear-gradient(
            ${props => props.theme.colors.lightBlue},
            ${props => props.theme.colors.zonaAzulFacilSpBlue}
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .gradient-icon {
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(#f00),
        to(#333)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.button-highlight {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      height: 44px;
      border-width: 0;
      border-radius: 50px;
      background-image: linear-gradient(
        to right,
        ${props => props.theme.colors.lightBlue} 0%,
        ${props => props.theme.colors.zonaAzulFacilSpBlue} 100%
      );

      div.button-text-container {
        display: grid;
        align-items: center;
        margin: 0 16px;
        grid-template-columns: 30px 1fr;
        grid-column-gap: 8px;
        grid-template-areas:
          "button-icon button-top-text"
          "button-icon button-bottom-text";

        span.button-icon {
          grid-area: button-icon;
          color: white;
        }

        span.button-top-text {
          grid-area: button-top-text;
          color: white;
        }

        span.button-bottom-text {
          grid-area: button-bottom-text;
          color: white;
        }
      }
    }
  }
`;
