import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeft,
  faLongArrowAltLeft,
  faLongArrowAltRight
} from "@fortawesome/free-solid-svg-icons";

import {
  Container,
  SectionSeparator,
  SectionTitle,
  Content,
  Title,
  QuotationMarkContainer,
  Description,
  Author,
  CurrentAvatar,
  TopLeftAvatar,
  TopRightAvatar,
  LeftTopAvatar,
  LeftBottomAvatar,
  BottomLeftAvatar,
  BottomRightAvatar,
  AuthorOccupation,
  LeftArrowContainer,
  RightArrowContainer
} from "./styles";

import fabianoGregorio from "../../../../assets/images/userReviews/fabiano-gregorio.jpg";
import luigiSariaAvatar from "../../../../assets/images/userReviews/luigi-saria.jpg";
import jessicaMariaDosSantos from "../../../../assets/images/userReviews/jessica-maria-dos-santos.jpg";
import monicaBienemannAvatar from "../../../../assets/images/userReviews/monica-bienemann.jpg";
import paulaBarbosaAvatar from "../../../../assets/images/userReviews/paula-barbosa.jpg";
import muriloBatistaAvatar from "../../../../assets/images/userReviews/murilo-batista.jpg";
import { userReviewsData } from "./data";

const Reviews = () => {
  const [userReviews, setUserReviews] = useState(userReviewsData);
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState(0);

  const selectNextReview = () => {
    currentSelectedIndex + 1 > userReviews.length - 1
      ? setCurrentSelectedIndex(0)
      : setCurrentSelectedIndex(currentSelectedIndex + 1);
  };

  const selectPreviousReview = () => {
    currentSelectedIndex - 1 < 0
      ? setCurrentSelectedIndex(userReviews.length - 1)
      : setCurrentSelectedIndex(currentSelectedIndex - 1);
  };

  return (
    <Container>
      <SectionSeparator />
      <SectionTitle>Avaliações</SectionTitle>
      <Content>
        <CurrentAvatar avatarImage={userReviews[currentSelectedIndex].avatar} />
        <TopLeftAvatar avatarImage={fabianoGregorio} />
        <TopRightAvatar avatarImage={jessicaMariaDosSantos} />
        <LeftTopAvatar avatarImage={luigiSariaAvatar} />
        <LeftBottomAvatar avatarImage={monicaBienemannAvatar} />
        <BottomLeftAvatar avatarImage={paulaBarbosaAvatar} />
        <BottomRightAvatar avatarImage={muriloBatistaAvatar} />
        <Title>O que nossos usuários já disseram</Title>
        <QuotationMarkContainer>
          <FontAwesomeIcon icon={faQuoteLeft} size="3x" />
        </QuotationMarkContainer>
        <Description>{userReviews[currentSelectedIndex].comment}</Description>
        <Author>{userReviews[currentSelectedIndex].author}</Author>
        <AuthorOccupation>
          {userReviews[currentSelectedIndex].authorOccupation}
        </AuthorOccupation>
        <LeftArrowContainer onClick={selectPreviousReview}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} size="2x" />
        </LeftArrowContainer>
        <RightArrowContainer onClick={selectNextReview}>
          <FontAwesomeIcon icon={faLongArrowAltRight} size="2x" />
        </RightArrowContainer>
      </Content>
    </Container>
  );
};

export default Reviews;
