import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button } from "./styles";

const DownloadButton = ({ icon, topText, bottomText, className, href }) => (
  <Button href={href}>
    <button className={className}>
      <div className="button-text-container">
        <span className="button-icon">
          <FontAwesomeIcon icon={icon} size="2x" />
        </span>
        <span className="button-top-text">{topText}</span>
        <span className="button-bottom-text">{bottomText}</span>
      </div>
    </button>
  </Button>
);

export default DownloadButton;
