export default {
  colors: {
    // Palette colors
    darkBlue: "#284d64",
    lightGreen: "#7fcfab",
    tomato: "#ef6f6c",
    lightBlue: "#0992a5",
    orange: "#f79256",
    zonaAzulFacilSpBlue: "#0f4f7e",

    // Extra colors
    black: "#333",
    softBlack: "#424242",
    white: "#FFF",
    disabled: "#BDBDBD"
  }
};
