import React from "react";
import { Link } from "react-router-dom";

import {
  Container,
  LogoImage,
  TitleContact,
  ContactContainer,
  TitleQuickLinks,
  QuickLinksContainer,
  FooterSeparator
} from "./styles";
import Logo from "../../assets/images/logo.png";

const Footer = () => (
  <Container>
    <LogoImage src={Logo} height={60} />
    <div className="footer-description">
      <p className="footer-description-text">
        O app mais bem avaliado de São Paulo
      </p>
    </div>
    <TitleContact>Contato</TitleContact>
    <ContactContainer>
      <p className="contact-info">
        <span className="contact-info-title">E-mail: </span>
        <span className="contact-info-description">
          contato@zonaazulfacil.com
        </span>
      </p>
      {/* <p className="contact-info">
        <span className="contact-info-title">Telefone: </span>
        <span className="contact-info-description">(11) 2925-2505</span>
      </p> */}
    </ContactContainer>
    <TitleQuickLinks>Links rápidos</TitleQuickLinks>
    <QuickLinksContainer>
      {/* <Link to="/">Início</Link> */}
      <a href="http://www.inova.app">Inova</a>
    </QuickLinksContainer>
    <FooterSeparator />
    <div className="footer-copyright">
      © {new Date().getFullYear()} Inova Soluções em Tecnologia e Gestão.
    </div>
  </Container>
);

export default Footer;
