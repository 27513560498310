import React from "react";
import { Link } from "react-router-dom";

import { Container, Navbar } from "./styles";
import logo from "../../assets/images/logo.png";
// import logoIcon from "../../assets/images/logo-icon.png";

const Header = () => (
  <Container>
    <Link to="/" className="logo-wrapper">
      <img src={logo} className="logo" alt="Logo" height={60} />
    </Link>
    <Link to="/" className="logo-icon-wrapper">
      <img src={logo} className="logo-icon" alt="Logo" />
    </Link>
    <Navbar>
      <ul>
        <li>
          {/* <Link to="/frotista">Frotista</Link> */}
          <a href="https://zonaazulfacil.com/portal/">Portal web</a>
        </li>
      </ul>
    </Navbar>
  </Container>
);

export default Header;
